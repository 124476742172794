<template>
    <div>
        <p>
            Liste des derniers changements
            <span class="update-time" v-if="lastUpdate">
                mise à jour le {{ lastUpdate.format('DD/MM/YYYY') }} à
                {{ lastUpdate.format('HH:mm') }}
            </span>
        </p>

        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="serverItemsLength"
            :loading="isLoading"
            loading-text="Chargement des données..."
            :options.sync="options"
            :footer-props="footerProps"
            must-sort
        >
            <template v-slot:[`item.nom`]="{ item }">
                {{ getBeneficiary(item) }}
            </template>

            <template v-slot:[`item.eor_cd`]="{ item }">
                {{ item.eor_cd }} - {{ item.lib_eor_cd }}
            </template>

            <template v-slot:[`item.date_debut`]="{ item }">
                {{ item.date_debut | formatDateTime }}
            </template>

            <template v-slot:[`item.date_fin`]="{ item }">
                {{ item.date_fin | formatDateTime }}
            </template>

            <template v-slot:[`item.date_modification`]="{ item }">
                {{ item.date_modification | formatDateTime }}
                <span class="modified-by" v-if="!isBeneficiaryLastModifier(item)">
                    par
                    <strong>{{ getLastModifier(item) }}</strong>
                </span>
            </template>

            <template v-slot:[`item.indisponibilite`]="{ item }">
                <span v-if="item.is_constraint === OK">
                    {{ item.lib_absence }}
                </span>
                <span v-else-if="item.fl_assim_travail === OK">
                    {{ item.indisponibilite }} - {{ item.lib_absence }}
                </span>
                <span v-else>Non assimilé à du temps de travail</span>
            </template>

            <template v-slot:[`item.statut`]="{ item }">
                <td class="pl-1">
                    <StatusCellSection v-if="item" :item="item" />
                </td>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom v-if="isOrdo || isDispatcheur">
                    <template v-slot:activator="{ on }">
                        <div class="action-btn" v-on="on">
                            <v-btn
                                class="check-unavailability"
                                icon
                                :ripple="false"
                                :class="isUnavailabilityAcknowledged(item) ? 'primary' : ''"
                                :disabled="
                                    isUnavailabilityAcknowledged(item) &&
                                    !isAcknowledgerCurrentEmployee(item)
                                "
                                @click="checkUnavailability(item)"
                            >
                                <v-icon v-if="!isUnavailabilityAcknowledged(item)">
                                    mdi-calendar-question
                                </v-icon>
                                <v-icon v-if="isUnavailabilityAcknowledged(item)">
                                    mdi-calendar-check
                                </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span v-if="!isUnavailabilityAcknowledged(item)">
                        Marquer comme pris en compte
                    </span>
                    <span v-else>
                        Pris en compte par
                        {{
                            isAcknowledgerCurrentEmployee(item) ? 'moi-même' : getAcknowledger(item)
                        }}
                        {{ getLastAcknowledgementDate(item) | formatDate }} à
                        {{ getLastAcknowledgementDate(item) | formatTime }}
                    </span>
                </v-tooltip>

                <v-tooltip v-if="item.commentaire_declarant || item.commentaire_valideur" bottom>
                    <template v-slot:activator="{ on }">
                        <div class="action-btn" v-on="on">
                            <v-btn icon @click="openMenu($event, item, true)">
                                <v-icon>mdi-message-text</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>Voir les commentaires</span>
                </v-tooltip>

                <v-tooltip v-if="item.constraint_informations" bottom>
                    <template v-slot:activator="{ on }">
                        <div class="action-btn" v-on="on">
                            <v-btn icon @click="openMenu($event, item, false)">
                                <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>Voir les détails de la contrainte</span>
                </v-tooltip>
            </template>

            <template v-slot:no-data>
                <div class="pa-3">
                    <p class="bold">Aucune donnée à afficher.</p>
                    <p>
                        Définissez vos paramètres d’affichage puis cliquez sur "Rafraîchir les
                        résultats".
                    </p>
                </div>
            </template>

            <template v-slot:[`footer.page-text`]>
                <div v-if="!isLoading && items && items.length > 0">
                    {{ offset + 1 }}-{{ offset + items.length }} sur {{ serverItemsLength }}
                </div>
                <div v-if="items && items.length === 0">Aucun élément à afficher</div>
            </template>
        </v-data-table>

        <v-menu
            v-model="isMenuOpened"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
            bottom
            max-width="450"
        >
            <CommentsSection v-if="menuItem && isCommentMenu" :item="menuItem" />
            <v-card tile v-if="menuItem && !isCommentMenu">
                <v-card-text>
                    <ConstraintDetailsSection :unavailability="menuItem" />
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex';
import moment from 'moment';

import lastChangesConstants from '@/constants/lastChanges';
import { OK, NO } from '@/constants/shared';
import {
    DATE_TIME_FORMAT,
    DATE_FORMAT,
    TIME_FORMAT,
    ISO_DATE_TIME_FORMAT,
} from '@/constants/dateFormat';

import lastChangesApi from '@/api/lastChanges';

import CommentsSection from '@/components/sections/table/CommentsSection.vue';
import StatusCellSection from '@/components/sections/table/StatusCellSection.vue';
import ConstraintDetailsSection from '@/components/sections/unavs/ConstraintDetailsSection.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
    mapGetters: employeeNameSpace.mapGetters,
};

const fileName = 'LastChangesDataTable.vue';

export default {
    name: 'LastChangesDataTable',

    components: {
        CommentsSection,
        StatusCellSection,
        ConstraintDetailsSection,
    },

    props: {
        filtersData: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            lastUpdate: undefined,

            OK,
            NO,
            headers: lastChangesConstants.TABLE_HEADERS_DATA,
            footerProps: {
                itemsPerPageText: 'Éléments par page : ',
                itemsPerPageOptions: [10, 20, 30],
            },

            items: [],
            serverItemsLength: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['date_modification'],
                sortDesc: [true],
            },

            menuItem: {},
            isCommentMenu: false,
            selectedElement: null,
            isMenuOpened: false,
        };
    },

    computed: {
        ...employeeStore.mapState(['employee']),
        ...employeeStore.mapGetters(['isOrdo', 'isDispatcheur']),

        offset() {
            return (this.options.page - 1) * this.options.itemsPerPage;
        },
    },

    watch: {
        filtersData() {
            this.options = {
                ...this.options,
                page: 1,
            };
        },
        options: {
            handler(newVal, oldVal) {
                if (newVal.page === oldVal.page) {
                    this.options.page = 1;
                }
                this.getTableData();
            },
            deep: true,
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
        formatDate(date) {
            return date ? moment(date).format(DATE_FORMAT) : '';
        },
        formatTime(date) {
            return date ? moment(date).format(TIME_FORMAT) : '';
        },
    },

    methods: {
        ...mapMutations(['showErrorToast']),

        async getTableData() {
            this.items = [];
            this.showLoading();

            try {
                const responseData = await lastChangesApi.getLastChangesUnavailabilities(
                    this.filtersData.startDate,
                    this.filtersData.endDate,
                    this.filtersData.startModifyingDate,
                    this.filtersData.endModifyingDate,
                    this.filtersData.eorCodes,
                    this.filtersData.selectedBeneficiaries,
                    this.filtersData.isConstraint,
                    this.filtersData.isWorkingTime,
                    this.filtersData.statuses,
                    this.options.sortBy,
                    this.options.sortDesc,
                    this.offset,
                    this.options.itemsPerPage
                );

                this.items = responseData.items;
                this.serverItemsLength = responseData.count;
                this.hideLoading();

                this.lastUpdate = moment();
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideLoading();
            }
        },

        openMenu(nativeEvent, item, isCommentMenu) {
            const open = () => {
                this.menuItem = item;
                this.isCommentMenu = isCommentMenu;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.showMenu()));
            };

            if (this.isMenuOpened) {
                this.hideMenu();
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },

        getBeneficiary(item) {
            return `${item.nom} ${item.prenom}`;
        },
        getLastModifier(item) {
            return `${item.nom_modification} ${item.prenom_modification}`;
        },
        isBeneficiaryLastModifier(item) {
            return this.getBeneficiary(item) === this.getLastModifier(item);
        },
        isUnavailabilityAcknowledged(item) {
            if (item.acknowledgement) {
                return item.acknowledgement.is_acknowledged === OK;
            }
            return false;
        },
        getAcknowledger(item) {
            if (item.acknowledgement) {
                return `${item.acknowledgement.acknowledger_last_name} ${item.acknowledgement.acknowledger_first_name}`;
            }
            return '';
        },
        getLastAcknowledgementDate(item) {
            if (item.acknowledgement) {
                return item.acknowledgement.last_acknowledgement;
            }
            return null;
        },
        isAcknowledgerCurrentEmployee(item) {
            return this.getAcknowledger(item) === `${this.employee.nom} ${this.employee.prenom}`;
        },
        async checkUnavailability(unavailability) {
            if (unavailability.acknowledgement && unavailability.acknowledgement.is_acknowledged) {
                try {
                    await lastChangesApi.checkUnavailability(
                        unavailability.id,
                        unavailability.acknowledgement.is_acknowledged === OK ? NO : OK
                    );

                    this.items = this.items.map((item) => {
                        if (item.id === unavailability.id) {
                            if (unavailability.acknowledgement.is_acknowledged === NO) {
                                item.acknowledgement = {
                                    is_acknowledged: OK,
                                    acknowledger_last_name: this.employee.nom,
                                    acknowledger_gid: this.employee.gid_collaborateur,
                                    acknowledger_first_name: this.employee.prenom,
                                    last_acknowledgement: moment().format(ISO_DATE_TIME_FORMAT),
                                };
                            } else if (unavailability.acknowledgement.is_acknowledged === OK) {
                                item.acknowledgement = {
                                    is_acknowledged: NO,
                                    acknowledger_last_name: null,
                                    acknowledger_gid: null,
                                    acknowledger_first_name: null,
                                    last_acknowledgement: null,
                                };
                            }
                        }
                        return item;
                    });
                } catch (error) {
                    this.showErrorToast({ error, fileName });
                }
            }
        },

        showMenu() {
            this.isMenuOpened = true;
        },
        hideMenu() {
            this.isMenuOpened = false;
        },
        showLoading() {
            this.$emit('update:isLoading', true);
        },
        hideLoading() {
            this.$emit('update:isLoading', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .header {
    background-color: $very-light-gray;
}
.modified-by {
    color: $primary !important;
    display: block;
    font-style: italic;
    line-height: 0.6rem;
    font-size: 0.7rem;
    margin-left: -0.2rem;
}
.update-time {
    padding-left: 8px;
    font-size: small;
    font-style: italic;
    color: $dark-gray;
}
.check-unavailability:hover {
    color: $primary;
}
.action-btn {
    display: inline;
}
</style>
