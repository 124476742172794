<template>
    <div class="legend">
        <span v-for="(legend, index) in legends" :key="index">
            <v-icon :color="legend.color">{{ legend.icon }}</v-icon>
            {{ legend.text }}
        </span>
    </div>
</template>

<script>
import { STATUT_INDISPO_LABEL } from '@/constants/unavailabilities';

export default {
    name: 'TableDataLegendSection',
    data() {
        return {
            legends: [
                {
                    color: 'success',
                    icon: 'mdi-check-circle-outline',
                    text: `${STATUT_INDISPO_LABEL.VALIDEE} / ${STATUT_INDISPO_LABEL.CREE}`,
                },
                {
                    color: 'gray',
                    icon: 'mdi-progress-check',
                    text: STATUT_INDISPO_LABEL.AVALIDER,
                },
                {
                    color: 'warning',
                    icon: 'mdi-close-circle-outline',
                    text: STATUT_INDISPO_LABEL.ANNULEE,
                },
                {
                    color: 'gray',
                    icon: 'mdi-progress-close',
                    text: STATUT_INDISPO_LABEL.ATTENTEANNULATION,
                },
                {
                    color: 'error',
                    icon: 'mdi-minus-circle-outline',
                    text: STATUT_INDISPO_LABEL.REFUSEE,
                },
            ],
        };
    },
};
</script>

<style lang="css" scoped>
.legend {
    font-size: 11px;
}
</style>
