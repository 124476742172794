const NON_WORKING_TIME_REASON_VALUE = 'nonWorkingTimeReasons';
const WORKING_TIME_REASON_VALUE = 'workingTimeReasons';
const CONSTRAINT_REASON_VALUE = 'constraintReasons';

const REASONS_TEXT_SELECT_DATA = [
    {
        text: 'Motifs RH non assimilés à du temps de travail',
        value: NON_WORKING_TIME_REASON_VALUE,
    },
    {
        text: 'Motifs RH assimilés à du temps de travail',
        value: WORKING_TIME_REASON_VALUE,
    },
    {
        text: 'Contraintes',
        value: CONSTRAINT_REASON_VALUE,
    },
];

const TABLE_HEADERS_DATA = [
    {
        text: 'Statut',
        value: 'statut',
        class: 'header statuses',
        width: '85px',
    },
    {
        text: 'Date de modification',
        value: 'date_modification',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Bénéficiaire',
        value: 'nom',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Périmètre',
        value: 'eor_cd',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Début indisponibilité',
        value: 'date_debut',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Fin indisponibilité',
        value: 'date_fin',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Motif',
        value: 'indisponibilite',
        class: 'header',
        width: '175px',
    },
    {
        text: 'Actions',
        value: 'actions',
        class: 'header',
        sortable: false,
        width: '125px',
    },
];

export default {
    CONSTRAINT_REASON_VALUE,
    WORKING_TIME_REASON_VALUE,
    NON_WORKING_TIME_REASON_VALUE,
    REASONS_TEXT_SELECT_DATA,
    TABLE_HEADERS_DATA,
};
