<template>
    <v-container fluid>
        <p>Paramètres d'affichage des derniers changements</p>

        <v-row class="filters" align="start">
            <v-col cols="12" sm="6" lg="3" class="text-color">
                <v-radio-group v-model="isDefaultAnalysisPeriod" row>
                    <v-radio label="Journée passée" :value="true" />
                    <v-radio label="Période définie" :value="false" />
                </v-radio-group>

                <v-row v-if="!isDefaultAnalysisPeriod">
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            label="Début"
                            prepend-icon="mdi-calendar"
                            v-model="analysisStartDate"
                            :max="analysisEndDate"
                        />
                    </v-col>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            label="Fin"
                            prepend-icon="mdi-calendar"
                            v-model="analysisEndDate"
                            :min="analysisStartDate"
                            :max="todayDate"
                        />
                    </v-col>
                </v-row>

                Période de planification :
                <v-row>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            label="Début d'indisponibilité"
                            prepend-icon="mdi-calendar"
                            v-model="unavStartDate"
                            :max="unavEndDate"
                        />
                    </v-col>
                    <v-col>
                        <TextDatePicker
                            dense
                            readonly
                            clearable
                            label="Fin d'indisponibilité"
                            prepend-icon="mdi-calendar"
                            v-model="unavEndDate"
                            :min="unavStartDate"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="6" lg="4" offset-lg="1" align-self="center" class="mt-9">
                <v-row>
                    <TextSelectPicker
                        clearable
                        dense
                        no-data-text="Aucune donnée à afficher."
                        v-model="selectedPerimeter"
                        :items="perimeterEntities"
                        label="Périmètre"
                        item-text="nom"
                        item-value="id"
                        multiple
                        chipLabel="id"
                        :maxChipsDisplayed="3"
                        deletableChips
                    />
                </v-row>

                <v-row>
                    <GroupSelectionPicker
                        clearable
                        dense
                        no-data-text="Aucune donnée à afficher."
                        :population="perimeterPeople"
                        v-model="selectedBeneficiaries"
                        label="Bénéficiaires"
                        multiple
                        :maxChipsDisplayed="2"
                        deletableChips
                    />
                </v-row>

                <v-row>
                    <TextSelectPicker
                        clearable
                        dense
                        no-data-text="Aucune donnée à afficher."
                        v-model="selectedReasons"
                        :items="reasons"
                        label="Motif"
                        multiple
                        :maxChipsDisplayed="1"
                        deletableChips
                    />
                </v-row>

                <v-row>
                    <TextSelectPicker
                        clearable
                        dense
                        no-data-text="Aucune donnée à afficher."
                        v-model="selectedStatuses"
                        :items="statuses"
                        label="Statut"
                        multiple
                        :maxChipsDisplayed="3"
                        deletableChips
                    />
                </v-row>
            </v-col>

            <v-col cols="12" lg="3" offset-lg="1" align-self="end">
                <v-btn
                    block
                    depressed
                    tile
                    outlined
                    class="mb-3"
                    :disabled="isLoading"
                    @click="initFilters()"
                >
                    Réinitialiser les filtres
                </v-btn>
                <v-btn
                    block
                    depressed
                    tile
                    class="primary"
                    :disabled="isLoading"
                    @click="updateFilters()"
                >
                    Rafraîchir les résultats
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';

import { OK, NO, STATUS_TEXT_SELECT_DATA } from '@/constants/shared';
import { STATUT_INDISPO } from '@/constants/unavailabilities';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';
import lastChangesConstant from '@/constants/lastChanges';

import { removeDuplicates } from '@/services/shared';

import TextSelectPicker from '@/components/pickers/TextSelectPicker.vue';
import TextDatePicker from '@/components/pickers/TextDatePicker.vue';
import GroupSelectionPicker from '@/components/pickers/GroupSelectionPicker.vue';

export default {
    name: 'LastChangesFilters',

    components: {
        TextDatePicker,
        TextSelectPicker,
        GroupSelectionPicker,
    },

    props: {
        perimeterEntities: {
            type: Array,
            required: true,
        },
        perimeterPeople: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            todayDate: moment().format(ISO_DATE_FORMAT),
            yesterdayDate: moment().subtract(1, 'd').format(ISO_DATE_FORMAT),

            analysisStartDate: '',
            analysisEndDate: '',

            unavStartDate: '',
            unavEndDate: '',

            selectedPerimeter: [],
            selectedBeneficiaries: [],

            reasons: lastChangesConstant.REASONS_TEXT_SELECT_DATA,
            selectedReasons: [],

            statuses: STATUS_TEXT_SELECT_DATA,
            selectedStatuses: [],
        };
    },

    computed: {
        isDefaultAnalysisPeriod: {
            get() {
                return (
                    this.analysisStartDate === this.yesterdayDate &&
                    this.analysisEndDate === this.todayDate
                );
            },
            set(val) {
                if (val) {
                    this.initAnalysisPeriod();
                } else {
                    this.analysisStartDate = moment().subtract(1, 'w').format(ISO_DATE_FORMAT);
                }
            },
        },
    },

    created() {
        this.initFilters();
    },

    methods: {
        initAnalysisPeriod() {
            this.analysisStartDate = this.yesterdayDate;
            this.analysisEndDate = this.todayDate;
        },
        initFilters() {
            this.initAnalysisPeriod();

            this.unavStartDate = '';
            this.unavEndDate = '';

            this.selectedPerimeter = [];
            this.selectedBeneficiaries = [];
            this.selectedReasons = [];
            this.selectedStatuses = [
                STATUT_INDISPO.VALIDEE,
                STATUT_INDISPO.CREE,
                STATUT_INDISPO.ANNULEE,
            ];

            this.updateFilters();
        },

        updateFilters() {
            const lastChangesFiltersData = {
                startDate: this.unavStartDate,
                endDate: this.unavEndDate,

                startModifyingDate: this.analysisStartDate,
                endModifyingDate: this.analysisEndDate,

                eorCodes: this.selectedPerimeter,
                selectedBeneficiaries: removeDuplicates(this.selectedBeneficiaries.flat()),

                isWorkingTime: this.getIsWorkingTimeFlag(),

                isConstraint: this.getIsConstraintFlag(),

                statuses: this.selectedStatuses,
            };
            this.$emit('update-filters', lastChangesFiltersData);
        },

        getIsWorkingTimeFlag() {
            if (
                this.selectedReasons.includes(lastChangesConstant.NON_WORKING_TIME_REASON_VALUE) &&
                !this.selectedReasons.includes(lastChangesConstant.WORKING_TIME_REASON_VALUE)
            ) {
                return NO;
            }
            if (
                !this.selectedReasons.includes(lastChangesConstant.NON_WORKING_TIME_REASON_VALUE) &&
                this.selectedReasons.includes(lastChangesConstant.WORKING_TIME_REASON_VALUE)
            ) {
                return OK;
            }
            return undefined;
        },

        getIsConstraintFlag() {
            if (this.selectedReasons && this.selectedReasons.length) {
                return this.selectedReasons.includes(lastChangesConstant.CONSTRAINT_REASON_VALUE)
                    ? OK
                    : NO;
            }
            return undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

p {
    margin-left: -16px;
    margin-bottom: 24px;
}

.filters {
    margin-bottom: 24px;
}
.text-color {
    color: $black;
}
</style>
