<template>
    <div>
        <LastChangesFilters
            v-if="perimeterEntities"
            :perimeterEntities="perimeterEntities"
            :perimeterPeople="perimeterPeople"
            :isLoading="isLoading"
            @update-filters="onFiltersUpdate"
        />

        <LastChangesDataTable
            v-if="filtersData"
            :filtersData="filtersData"
            :isLoading.sync="isLoading"
        />

        <TableDataLegendSection />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import LastChangesFilters from '@/components/lastChanges/LastChangesFilters.vue';
import LastChangesDataTable from '@/components/lastChanges/LastChangesDataTable.vue';
import TableDataLegendSection from '@/components/sections/table/TableDataLegendSection.vue';

const perimeterNameSpace = createNamespacedHelpers('perimeter');
const perimeterStore = {
    mapGetters: perimeterNameSpace.mapGetters,
    mapActions: perimeterNameSpace.mapActions,
};

export default {
    name: 'LastChangesPage',

    components: {
        LastChangesFilters,
        LastChangesDataTable,
        TableDataLegendSection,
    },

    data() {
        return {
            filtersData: undefined,
            isLoading: false,
        };
    },

    computed: {
        ...perimeterStore.mapGetters(['perimeterEntities', 'perimeterPeople']),
    },

    methods: {
        ...perimeterStore.mapActions(['loadPerimeter']),

        onFiltersUpdate(val) {
            this.filtersData = val;
        },
    },

    async created() {
        await this.loadPerimeter();
    },
};
</script>
