import { formatToIsoString, formatToIsoStringEndOfDay } from '@/services/shared';
import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'lastChanges.api.js';

async function getLastChangesUnavailabilities(
    startDate,
    endDate,
    startModifyingDate,
    endModifyingDate,
    eorCodes,
    selectedBeneficiaries,
    isConstraint,
    isWorkingTime,
    statuses,
    sortBy,
    sortDesc,
    offset,
    limit
) {
    const params = new URLSearchParams();
    if (startDate) {
        params.append('date_debut', formatToIsoString(startDate));
    }
    if (endDate) {
        params.append('date_fin', formatToIsoStringEndOfDay(endDate));
    }
    if (startModifyingDate) {
        params.append('date_modification_debut', formatToIsoString(startModifyingDate));
    }
    if (endModifyingDate) {
        params.append('date_modification_fin', formatToIsoStringEndOfDay(endModifyingDate));
    }
    if (eorCodes) {
        params.append('eor_codes', eorCodes);
    }
    if (selectedBeneficiaries && selectedBeneficiaries.length) {
        params.append('gid_collaborateur', selectedBeneficiaries);
    }
    if (isConstraint) {
        params.append('is_constraint', isConstraint);
    }
    if (isWorkingTime) {
        params.append('fl_assim_travail', isWorkingTime);
    }
    if (statuses) {
        params.append('status', statuses);
    }
    if (sortBy && sortBy.length > 0) {
        params.append('order', sortBy[0]);
    }
    if (sortDesc && sortDesc.length > 0) {
        params.append('asc', !sortDesc[0]);
    }
    params.append('offset', offset);
    params.append('limit', limit);

    try {
        const response = await apiClient.get('/unavailabilities/last_changes', { params });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.LAST_CHANGES_UNAVAILABILITIES, fileName)
        );
    }
}

async function checkUnavailability(unavailabilityId, acknowledged) {
    try {
        return await apiClient.put(`unavailabilities/${unavailabilityId}/acknowledgement`, {
            acknowledged,
        });
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.CHECK_UNAVAILABILITY, fileName));
    }
}

export default {
    getLastChangesUnavailabilities,
    checkUnavailability,
};
