<template>
    <v-card tile>
        <v-card-text>
            <div v-if="item.commentaire_declarant">
                <v-icon color="primary" left>mdi-message-text</v-icon>
                <span class="bold">Commentaire déclarant</span>

                <p>{{ item.commentaire_declarant }}</p>
            </div>

            <div v-if="item.commentaire_valideur">
                <v-icon color="accent" left>mdi-message-reply-text</v-icon>
                <span class="bold">Commentaire valideur</span>

                <p>{{ item.commentaire_valideur }}</p>
            </div>

            <div v-if="item.com_declarant_annulation">
                <v-icon left>mdi-message-text</v-icon>
                <span class="bold">Commentaire annulation</span>

                <p>{{ item.com_declarant_annulation }}</p>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'CommentsSection',

    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>
